.device {
  width: 60%;
  position: absolute;
  left: 20%;
  top: 5%;
}

.onOffButton {
  position: absolute;
  top: 309px;
  left: 32%;
  width: 6%;
  cursor: url(/pointer.png), auto;
}

.screen {
  position: absolute;
  top: 11.3%;
  left: 35.7%;
  width: 30%;
}

.idx {
  position: absolute;
  top: 432px;
  width: 52px;
  text-align: center;
  font-size: 1em;
}
