.page {
  margin: 40px auto;
  text-align: center;
}

.pageContent {
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 40px;
  padding-right: 40px;
}

.escapeRoomHeader {
  font-family: 'Allerta Stencil', sans-serif;
}

.soundWrapper {
  margin-bottom: 20px;
}

.sound {
  display: inline-block;
  cursor: url('/pointer.png'), auto;
  transition: transform ease 0.3s;
  color: #66101F;
}

.sound img {
  margin-bottom: 3px;
}

.sound:hover {
  transform: scale(1.1);
}

.step {
  margin-top: 20px;
  text-align: center;
}

.step span {
  display: inline-block;
  border: 1px solid #666;
  border-radius: 40px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  font-size: 24px;
}

.divider {
  clear: both;
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.divider:after {
  border-color: #9a6e4c !important;
}

.divider:before {
  border-color: #9a6e4c !important;
}

.divider span {
  font-size: 1.2em;
}

.nameInput {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.nameInput input {
  text-align: center;
}

.characterSelect {
  padding-left: 60px;
  text-align: center;
}

.ally,
.stefan {
  /*height: 260px;*/
  width: 280px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 24px;
  float: left;
  display: inline-block;
  position: relative;
  padding: 0px;
  border: 6px solid #DEC7CC;
  border-radius: 10px;
  text-align: left;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
}

.ally:hover,
.stefan:hover {
  border-color: #9E4D5C;
}

.ally.selected {
  border-color: #66101F;
}

.stefan.selected {
  border-color: #66101F;
}

.characterSelect h2 {
  margin-bottom: 0;
}

.ally img,
.stefan img {
  width: 100%;
  margin-bottom: 10px;
  /*position: absolute;
  left: 20px;
  top: 20px;*/
}

.playerList {
  list-style: none;
  padding-left: 0;
}

.playerList li {
  margin-bottom: 6px;
}

.name {
  margin-bottom: 0;
}

.nicknameAlly,
.nicknameStefan {
  color: #403328;
  margin-bottom: 0;
  font-size: 1.6em;
}

.nicknameAlly {
  color: #403328;
}