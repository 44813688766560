.flashlight {
  width: 100%;
  margin-top: 10%;
  position: absolute;
  left: 0;
  top: 50px;
}

.description {
  text-align: center;
  position: absolute;
  bottom: 0%;
  width: 100%;
}