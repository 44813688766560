
.container {
  margin: 30px auto;
}

.center {
  text-align: center;
}

.note {
  font-style: italic;
}

.explanation {
  color: #c00;
}

.explanationSuccess {
  color: #090;
}

.story {
  margin: 20px auto 40px;
  width: 760px;
  overflow: hidden;
}

.stepper {
  height: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  line-height: 1px;
}

.step1,
.step2,
.step3 {
  display: inline-block;
  height: 10px;
  width: 80px;
  border-radius: 10px;
  margin: 0px 20px;
  background-color: #DEC7CC;
  cursor: pointer;
}

.stepActive {
  background-color: #66101F;
}