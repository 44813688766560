.deviceContainer {

}

.device {
  width: 80%;
  margin-top: -10%;
}

.leftButton,
.leftButton:focus {
  width: 13%;
  height: 13%;
  position: absolute;
  left: 25%;
  top: 72.5%;
  background-color: #8181e0;
  border: 2px solid #2e2e4e;
  border-radius: 5px;
}

.rightButton,
.rightButton:focus {
  border-radius: 5px;
  width: 13%;
  height: 13%;
  position: absolute;
  left: 41.2%;
  top: 72.5%;
  background-color: #8181e0;
  border: 2px solid #2e2e4e;
}

.leftButton:hover,
.rightButton:hover {
  background-color: #555588;
  border: 2px solid #2e2e4e;
  cursor: url('/pointer.png'), auto;
}

.leftButton:active,
.rightButton:active {
  background-color: #2e2e4e;
  border: 2px solid #2e2e4e;
  cursor: url('/pointer.png'), auto;
}

.slice {
  width: 27%;
  position: absolute;
  top: 30%;
  left: 36%;
}

.slice2 {
  width: 25%;
  position: absolute;
  top: 40%;
  left: 37%;
}
