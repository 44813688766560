.speechBubbleContainer {
  z-index: 2010;
  position: fixed;
  /*top: 400px;*/
  bottom: 400px;
  right: 50%;
  width: 600px;
  margin-right: -300px;
  transition: opacity 500ms;
  /*border: 1px solid red;*/
}

.withDarkOverlay {
  bottom: auto;
  bottom: 400px;
}

.darkOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  transition: opacity 500ms;
  z-index: 1000;
}

.personHud {
  width: 220px;
  height: 278px;
  z-index: 1001;
  position: absolute;
  top: 0;
  right: 0;
  background-size: contain;
}

.avatar {
  position: absolute;
  right: 27px;
  top: 12px;
}

.avatar img {
  height: 230px;
}

.speechContent {
  position: absolute;
  right: 235px;
  width: 414px;
  padding: 27px;
  height: 177px;
  background-repeat: no-repeat;
  top: 44px;
  display: flex;
  align-items: center;
}

.nameContainer {
  line-height: 1.1em;
  width: 210px;
  top: 244px;
  right: 10px;
  position: absolute;
  z-index: 1005;
  text-align: center;
  font-size: 18px;
  color: #000;
}

.name {
  font-size: 0.9em;
}

.tagnameAlly,
.tagnameStefan,
.tagnameKiller {
  font-size: 1.4em;
  margin-bottom: 5px;
}

.nameContainer span {
  display: block;
}