.number {
  font-family:"PIXymbols Digit Clocks W90 Bd" !important;
  font-size:16px;font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  color: #3f3;
  font-size: 2.4em;
  position: absolute;
  left: 69.4%;
  width: 50px;
  text-align: center;
}

.arrowUp,
.arrowDown {
  height: 32.3px;
  width: 52px;
  /*border: 1px solid red;*/
  position: absolute;
  left: 60%;
  background-size: cover;
  font-size: 2.5em;
  line-height: 1.35em;
  text-align: center;
  cursor: url('/pointer.png'), auto;
  color: rgba(30, 30, 30, 0.6);
  overflow: hidden;
}

.arrowDown {
  top: 74.5%;
  transform: rotate(180deg);
}

.arrowUp:hover,
.arrowDown:hover {
  color: rgba(30, 30, 30, 0.8);
}

.arrowUp:active,
.arrowDown:active {
  color: rgba(30, 30, 30, 1);
}

.arrowDisabled {
  cursor: auto;
}

.arrowDisabled:hover {
  color: rgba(30, 30, 30, 0.6);
}

.number0 {
  top: 18%;
}

.arrowUp0 {
  top: 17.4%;
}

.arrowDown0 {
  top: 23.1%;
}

.number1 {
  top: 27.4%;
}

.arrowUp1 {
  top: 26.9%;
}

.arrowDown1 {
  top: 32.8%;
}

.number2 {
  top: 37.2%;
}

.arrowUp2 {
  top: 36.7%;
}

.arrowDown2 {
  top: 42.3%;
}

.number3 {
  top: 46.9%;
}

.arrowUp3 {
  top: 46.4%;
}

.arrowDown3 {
  top: 52.1%;
}

.buttonContainer {
  padding-top: 14px;
  text-align: center;
}

.submitButton {
  border: 3px solid #999;
  border-radius: 5px;
  height: auto;
  padding: 14px 40px;
  font-size: 1.5em;
}

.timer {
  font-family:"PIXymbols Digit Clocks W90 Bd" !important;
  font-size:16px;font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  color: #3f3;
  font-size: 1.9em;
  position: absolute;
  left: 14.4%;
  width: 420px;
  text-align: center;
  top: 9.6%;
}

.confirmModal {
  width: 700px !important;
}