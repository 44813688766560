.page {
  text-align: center;
  margin: 40px auto;
}

.chestWrapper {
  margin-top: 2em;
  margin-bottom: 1em;
}

.chest {
  width: 150px;
  height: 148px;
}

.escapeRoomHeader {
  font-size: 2em;
  text-align: center;
  font-weight: bold;
  margin-bottom: 1em;
}

.logo {
  text-align: center;
  margin-top: 0.5em;
  text-transform: uppercase;
}

.logo img {
  width: 170px;
}

.accessCodeInput {
  max-width: 400px;
  margin: 24px auto;
}

.accessCodeInput input {
  text-align: center;
}