.blackBackground {
  min-height: 1600px;
  min-width: 1080px;
  background-color: #000;
}

.chestBackground {
  background-image: url(/background.jpg);
  background-position: center -530px;
  background-size: 2400px;
  background-repeat: no-repeat;
  min-height: 1200px;
  min-width: 1080px;
}

.level1Background {
  background-image: url(/img/l1.jpg);
  background-position: center -523px;
  background-size: 2100px;
  background-repeat: no-repeat;
  min-height: 1200px;
  min-width: 1080px;
}

.level2BackgroundRed {
  background-image: url(/img/l2Red.jpg);
  background-position: center -523px;
  background-size: 2100px;
  background-repeat: no-repeat;
  min-height: 1200px;
  min-width: 1080px;
}

.level2BackgroundBlue {
  background-image: url(/img/l2Blue.jpg);
  background-position: center -523px;
  background-size: 2100px;
  background-repeat: no-repeat;
  min-height: 1200px;
  min-width: 1080px;
}

.level3BackgroundRed {
  background-image: url(/img/l3Red.jpg);
  background-position: center -528px;
  background-size: 2100px;
  background-repeat: no-repeat;
  min-height: 1200px;
  min-width: 1080px;
}

.level3BackgroundBlue {
  background-image: url(/img/l3Blue.jpg);
  background-position: center -528px;
  background-size: 2100px;
  background-repeat: no-repeat;
  min-height: 1200px;
  min-width: 1080px;
}

.level4BackgroundRed1 {
  background-image: url(/img/l4Red1.jpg);
  background-position: center -510px;
  background-size: 2100px;
  background-repeat: no-repeat;
  min-height: 1200px;
  min-width: 1080px;
}

.level4BackgroundBlue1 {
  background-image: url(/img/l4Blue1.jpg);
  background-position: center -510px;
  background-size: 2100px;
  background-repeat: no-repeat;
  min-height: 1200px;
  min-width: 1080px;
}

.level4BackgroundRed2 {
  background-image: url(/img/l4Red2.jpg);
  background-position: center -510px;
  background-size: 2100px;
  background-repeat: no-repeat;
  min-height: 1200px;
  min-width: 1080px;
}

.level4BackgroundBlue2 {
  background-image: url(/img/l4Blue2.jpg);
  background-position: center -510px;
  background-size: 2100px;
  background-repeat: no-repeat;
  min-height: 1200px;
  min-width: 1080px;
}

.level5BackgroundRed {
  background-image: url(/img/l5Red.jpg);
  background-position: center -528px;
  background-size: 2100px;
  background-repeat: no-repeat;
  min-height: 1200px;
  min-width: 1080px;
}

.level5BackgroundBlue {
  background-image: url(/img/l5Blue.jpg);
  background-position: center -528px;
  background-size: 2100px;
  background-repeat: no-repeat;
  min-height: 1200px;
  min-width: 1080px;
}

.wrapper {
  position: relative;
  width: 1080px;
  margin: 0 auto;
  min-height: 1000px;
}

.blur {
  filter: blur(5px);
  transition: filter 300ms;
}

.gameBoard {
  /*background-color: #fff;*/
  /*width: 933px;*/
  /*height: 700px;*/
  width: 920px;
  height: 725px;
  margin: 10px 10px 0;
  position: absolute;
  left: -10px;
}

.timerContainer {
  text-align: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 80px;
}

.timerContainerBomb {
  margin-left: -6px;
  margin-right: -6px;
}

.timer {
  padding-top: 2px;
  font-size: 1.5em;
  margin-bottom: 0;
}

.timerDanger {
  color: #ff5353;
}

.bombActivated {
  font-size: 0.5em;
  color: #ff5353;
}

.timerSecondLine {
  font-size: 0.5em;
  height: 1.5em;
}

.paused {
  font-size: 0.7em;
  margin-top: -7px;
  color: #000;
}

.stats {
  position: absolute;
  top: 20px;
  right: 0px;
  width: 150px;
  padding-top: 20px;
  color: rgb(218, 214, 206);
}

.detectiveContainer {
  text-align: center;
  position: relative;
  height: 300px;
  left: 2px;
}

.detectiveContainer2 {
  text-align: center;
  position: relative;
  left: 0;
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 20px;
}

.detectiveName1,
.detectiveName2 {
  position: absolute;
  top: 160px;
  width: 117px;
  left: 10px;
  color: #000;
  font-size: 0.8em;
}

.detectiveName2 {
  top: 4px;
  width: 103px;
  left: 34px;
}

.playerList1,
.playerList2 {
  height: 116px;
  width: 128px;
  line-height: 1em;
  position: absolute;
  top: 188px;
  left: 5px;
  display: flex;
  align-items: center;
}

.playerList2 {
  height: 129px;
  top: 28px;
  left: 5px;
}

.stats ul {
  padding-left: 0;
  list-style-type: none;
  font-size: 14px;
  width: 100%;
  margin-bottom: 0;
}

.stats ul li {
  line-height: 1.2em;
  margin-bottom: 7px;
}

.player {
  width: 100%;
  position: absolute;
  top: 10px;
  left: 7px;
  width: 122px;
}

.hudContainer1 {
  background-repeat: no-repeat;
  background-size: contain;
  height: 340px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
