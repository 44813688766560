.playArea {
  height: 580px;
  width: 660px;
  position: relative;
  left: 67px;
}

.grid {
  position: absolute;
  left: 113px;
  top: 38px;
  height: 510px;
  width: 517px;
}

.piece {
  position: absolute;
  cursor: url(/pointer.png), auto;
  transition: top 500ms, left 500ms;
}
