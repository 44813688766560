@font-face {
  font-family: "PIXymbols Digit Clocks W90 Bd";
  src: url("/fonts/35043a149682a862b352fa51c0e25869.eot"); /* IE9*/
  src: url("/fonts/35043a149682a862b352fa51c0e25869.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("/fonts/35043a149682a862b352fa51c0e25869.woff2") format("woff2"), /* chrome、firefox */
  url("/fonts/35043a149682a862b352fa51c0e25869.woff") format("woff"), /* chrome、firefox */
  url("/fonts/35043a149682a862b352fa51c0e25869.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("/fonts/35043a149682a862b352fa51c0e25869.svg#PIXymbols Digit Clocks W90 Bd") format("svg"); /* iOS 4.1- */
}

body {
  background-color: #000;
  /*background-color: #8300FF;*/
  /*background-image: url(/background.jpg);
  background-position: center;
  background-size: 1728px;
  min-height: 900px;*/
}

body, .App {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  /*cursor: url('/cursor.png'), auto;*/
}

img {
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -moz-user-select: none;
  -o-user-drag: none;
  user-drag: none;
  user-select: none;
  -ms-user-select: none;
}

button:focus,
div:focus,
video:focus {
  outline-style: none;
  box-shadow: none;
}

.ant-modal-close-x {
  font-size: 16px;
}

.ant-modal-content .ant-modal-header {
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  color: #eee;
  padding-right: 40px;
  padding-left: 40px;
}

.ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 1.5em;
  line-height: 1em;
  z-index: 10;
  color: #eee;
  padding-right: 60px;
  padding-left: 60px;
}

.transparent .ant-modal-content {
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  min-width: 1080px;

  /*position: static;*/
}


.game-confirm .ant-modal-confirm-body {
  padding: 20px;
}

.game-confirm .ant-modal-confirm-btns {
  float: none;
  text-align: center;
  padding-bottom: 40px;
}

.game-confirm .ant-modal-confirm-btns button {
  padding: 10px 20px;
  height: auto;
}

.game-confirm .ant-modal-confirm-body .ant-modal-confirm-content {
  font-size: 1.4em;
  text-align: center;
}

.game-confirm .ant-modal-confirm-body .ant-modal-confirm-title {
  font-size: 1.8em;
  text-align: center;
}
