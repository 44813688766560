.lockImageContainer {
  text-align: center;
  margin-top: -20px;
}

.lockImage {
  /*height: 480px;*/
  max-width: 900px;
  max-height: 580px;
}

.numberContainer {
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  height: 160px;
}

.number {
  height: 90px;
  width: 58px;
  position: absolute;
  overflow: hidden;
  top: 31%;
  background-size: cover;
  border: 5px solid #000;
  /*transition: background 400ms cubic-bezier(0.25, 0.1, 0.26, 1.61) 0s;*/
}

.number img {
  width: 51px;
  /*z-index: 1;*/
  transition: transform 400ms cubic-bezier(0.25, 0.1, 0.26, 1.61) 0s;
  position: absolute;
  top: 0;
  left: 0;
}

.arrowUp,
.arrowDown {
  height: 60px;
  width: 59px;
  position: absolute;
  top: 0%;
  background-size: cover;
  font-size: 4.2em;
  line-height: 1.5em;
  text-align: center;
  font-family: 'Indie Flower';
  cursor: url('/pointer.png'), auto;
  color: rgba(255, 255, 255, 0.6);
}

.arrowDown {
  top: 80%;
  transform: rotate(180deg);
}

.arrowUp:hover,
.arrowDown:hover {
  color: rgba(255, 255, 255, 1);
}

.arrowUp:active,
.arrowDown:active {
  color: rgba(255, 255, 0, 1);
}

.number0 {
  left: 34.3%;
}

.number1 {
  left: 42.9%;
}

.number2 {
  left: 51.6%;
}

.number3 {
  left: 60.4%;
}

.buttonContainer {
  padding-top: 14px;
  text-align: center;
}

.submitButton {
  border: 3px solid #eee;
  color: #eee;
  border-radius: 5px;
  height: auto;
  padding: 14px 40px;
  font-size: 1.2em;
  background-color: transparent;
}

.submitButton:hover,
.submitButton:focus,
.submitButton:active {
  background-color: transparent;
}
