.chest {
  width: 100%;
  margin-top: 1%;
}

.bluePadlockClickArea {
  width: 9%;
  height: 17%;
  position: absolute;
  top: 36%;
  left: 32%;
}

.redPadlockClickArea {
  width: 8%;
  height: 16%;
  position: absolute;
  top: 36%;
  left: 33%;
}

.blueDominoClickArea {
  width: 29%;
  height: 16%;
  position: absolute;
  top: 81%;
  left: 39%;
}

.redDominoClickArea {
  width: 28%;
  height: 21%;
  position: absolute;
  top: 55%;
  left: 22%;
}

.blueStarsClickArea {
  width: 20%;
  height: 21%;
  position: absolute;
  top: 26%;
  left: 64%;
}

.redStarsClickArea {
  width: 21%;
  height: 16%;
  position: absolute;
  top: 79%;
  left: 49%;
}

.blueSmudgesClickArea {
  width: 22.5%;
  height: 18%;
  position: absolute;
  top: 60%;
  left: 62%;
}

.redSmudgesClickArea {
  width: 22%;
  height: 21%;
  position: absolute;
  top: 3%;
  left: 49%;
}

.blueCaesarClickArea {
  width: 27.5%;
  height: 15%;
  position: absolute;
  top: 5%;
  left: 48%;
}

.redCaesarClickArea {
  width: 24%;
  height: 22%;
  position: absolute;
  top: 52%;
  left: 62%;
}

.blueDeviceClickArea {
  width: 30%;
  height: 22%;
  position: absolute;
  top: 58%;
  left: 17%;
}

.redDeviceClickArea {
  width: 30%;
  height: 24%;
  position: absolute;
  top: 23%;
  left: 58%;
}

.flashlightClickArea {
  width: 22%;
  height: 15%;
  position: absolute;
  top: 61.1%;
  left: 59.7%;
  z-index: 1;
}

.flashlight {
  width: 41%;
  position: absolute;
  top: 54%;
  left: 60%;
}

.whereIsThePaint {
  width: 24%;
  position: absolute;
  top: 8%;
  left: 58%;
  border: 2px dashed transparent;
  border-radius: 20px;
}

.paint {
  width: 24%;
  position: absolute;
  top: 2%;
  left: 52%;
  border: 2px dashed transparent;
  border-radius: 20px;
}

.domino {
  width: 16%;
  position: absolute;
  top: 47%;
  left: 55%;
  padding: 10px;
  background: #fff;
  border: 2px dashed transparent;
  border-radius: 20px;
}

.newspaperBlue {
  width: 16%;
  position: absolute;
  top: 57%;
  left: 71%;
  padding: 10px;
  border: 2px dashed transparent;
  border-radius: 20px;
}

.newspaperRed {
  width: 16%;
  position: absolute;
  top: 57%;
  left: 16%;
  padding: 10px;
  border: 2px dashed transparent;
  border-radius: 20px;
}

.tickets {
  width: 20%;
  position: absolute;
  top: 29%;
  left: 68%;
  border-radius: 20px;
}

.map {
  width: 20%;
  position: absolute;
  top: 58%;
  left: 35%;
  border: 2px solid #fff;
  border-radius: 20px;
}

.bluePadlockClickArea:hover,
.redPadlockClickArea:hover,
.blueDominoClickArea:hover,
.redDominoClickArea:hover,
.blueStarsClickArea:hover,
.redStarsClickArea:hover,
.blueSmudgesClickArea:hover,
.redSmudgesClickArea:hover,
.blueCaesarClickArea:hover,
.redCaesarClickArea:hover,
.blueDeviceClickArea:hover,
.redDeviceClickArea:hover,
.flashlightClickArea:hover,
.whereIsThePaint:hover,
.paint:hover,
.domino:hover,
.newspaperBlue:hover,
.newspaperRed:hover,
.tickets:hover,
.map:hover {
  border: 2px dashed #8300FF;
  border-radius: 20px;
  cursor: url('/glass.png'), auto;
}


.redWire,
.greenWire {
  position: absolute;
  width: 10%;
  left: 34.7%;
  top: 12%;
}

.greenWire {
  top: 16.2%;
}

.page {
  height: 102%;
  background-repeat: no-repeat;
  background-size: contain;
}