.congratulations {
  margin: 40px auto;
  text-align: center;
}

.congratulations h1,
.congratulations h2 {
  text-align: center;
}

.gifs {
  text-align: center;
  margin-bottom: 40px;
}

.gif {
  max-height: 250px;
  max-width: 250px;
  margin-bottom: 10px;
}

.shareButton {
  margin-right: 5px;
  margin-left: 5px;
}

.content {
  padding: 60px;
}

.divider {
  clear: both;
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.divider:after {
  border-color: #9a6e4c !important;
}

.divider:before {
  border-color: #9a6e4c !important;
}

.divider span {
  font-size: 1.2em;
}

.superheroes {
  max-width: 100%;
  margin-top: 1.5em;
}

.corpLogoWrapper {
  text-align: center;
}

.corpLogo {
  max-width: 50%;
  margin-bottom: 40px;
}

.parallelLab {
  max-width: 100%;;
  margin-bottom: 1em;
}

.errorMessage {
  color: #ff0000;
}