.beaker {
  height: 102px;
  width: 110px;
  background-size: contain;
}

.beakerDrag {
  width: 40%;
  float: left;
  margin: 4%;
  cursor: url('/pointer.png'), auto;
}

.beakerContainer {
  width: 50%;
  padding: 10%;
  margin-top: 15px;
}

.beakerMix {
  width: 100%;
  z-index: 2;
  position: relative;
}

.beakerDrop {
  color: #eee;
  position: absolute;
  top: 1%;
  left: 60%;
  padding: 40px;
  border-radius: 5px;
  width: 32%;
  text-align: center;
}

.beakerDrop p {
  height: 3em;
}

.color {
  position: absolute;
  top: 55%;
  left: 27%;
  width: 40%;
  height: 24%;
  z-index: 1;
}

.reset {
  position: absolute;
  top: 60%;
  left: 60%;
  padding: 0px;
  width: 30%;
  text-align: center;
}