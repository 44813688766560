.hints {
  /*font-size: 1.2em;*/
  text-align: center;
  border: 4px solid #666;
  border-radius: 20px;
  padding: 40px 20px;
  min-height: 600px;
  margin-top: 20px;
}

.steps {
  margin-top: 12px;
}

.hintContent {
  padding: 40px;
}