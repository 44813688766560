.frame {
  position: relative;
  width: 839px;
}

.frameTop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  height: 194px;
  z-index: 0;
}

.frameBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  height: 170px;
  z-index: 0;
}

.frameMiddle {
  position: absolute;
  top: 194px;
  bottom: 170px;
  background-repeat: repeat-y;
  left: 0;
  right: 0;
  z-index: 0;
}

.content {
  min-height: 600px;
  padding: 40px;
  z-index: 2;
  position: relative;
}