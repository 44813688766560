.container {
  position: relative;
  text-align: center;
}

.background {
  width: 100%;
}

.stage {
  position: absolute;
  top: 15%;
  left: 15.7%;
  height: 365px;
  width: 365px;
}