.level {
  height: 100%;
  background-size: contain;
}

.box {
  position: absolute;
  top: -5.1%;
  left: 38%;
  width: 63.6%;
  transition: opacity 2s;
  transition-delay: 500ms;
}

.boxClickArea {
  height: 60%;
  height: 51%;
  top: 0%;
  width: 57%;
  left: 41%;
}

.boxClickArea:hover {
  border: 2px dashed #ff0000;
  border-radius: 20px;
  cursor: url('/pointer.png'), auto;
}

.hidden {
  opacity: 0;
}

.knots {
  position: absolute;
  top: 0px;
  left: -2%;
  width: 960px;
}