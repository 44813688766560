.container {
  text-align: center;
  position: absolute;
  right: 50px;
  color: #eee;
}

.x {
  font-size: 3em;
  display: block;
  margin-bottom: -20px;
}
