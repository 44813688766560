.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
}

.indicator {
  background-color: #4f4;
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 8px;
  margin-right: 8px;
  border: 1px solid #666;
}

.label {
  font-size: 0.6em;
}