.device {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}

.valve1,
.valve2,
.valve3,
.valve4 {
  position: absolute;
  top: 42%;
  left: 39%;
  width: 100px;
  transition: transform 0.25s ease;
  z-index: 6;
}

.valve1:hover,
.valve2:hover,
.valve3:hover,
.valve4:hover {
  cursor: url('/pointer.png'), auto;
}

.valve2 {
  top: 58%;
  left: 39%
}

.valve3 {
  top: 42%;
  left: 56%
}

.valve4 {
  top: 58%;
  left: 56%
}

.pie1,
.pie2,
.pie3,
.pie4 {
  position: absolute;
  top: 21%;
  left: 6.2%;
  width: 100px;
  transition: transform 1s ease;
  z-index: 6;
}

.pie2 {
  top: 72%;
  left: 6.1%;
}

.pie3 {
  top: 22%;
  left: 72.6%
}

.pie4 {
  top: 73%;
  left: 72.8%
}

.handle {
  border: 2px dashed transparent;
  cursor: url(/pointer.png), auto;
  border-radius: 20px;
  height: 159px;
  width: 289px;
  position: absolute;
  top: 345px;
  left: 238px;
  z-index: 6;
}

.handle:hover {
  border: 2px dashed #ff0000;
}

.blocked {
  z-index: 6;
  position: absolute;
  top: 39%;
  left: 16.8%;
  opacity: 0;
}

.unblocked {
  opacity: 1;
}

.buttonUnblocked {
  width: 119px;
}

.buttonUnblockedActive {
  opacity: 0.2;
  width: 63px;
  position: absolute;
  top: 105px;
  left: 29px;
  cursor: url(/pointer.png), auto;
}

.buttonUnblockedActive:hover {
  opacity: 0.4;
}

.buttonUnblockedActive:active {
  opacity: 1;
}

.onOffButton {
  position: absolute;
  top: 31%;
  left: 67.4%;
  width: 9.8%;
  cursor: url(/pointer.png), auto;
  z-index: 6;
}

.waveformImageContainer {
  position: absolute;
  top: 60%;
  left: 19%;
  text-align: center;
  z-index: 6;
  width: 96px;
  height: 119px;
  overflow: hidden;
}

.waveformImage:hover {
  cursor: url('/pointer.png'), auto;
}

.waveformImage {
  height: 114px;
  left: -5px;
  position: relative;
  overflow: hidden;
}

.waveformImage img {
  width: 70px;
  opacity: 1;
  margin-top: -5px;
}

.waveformImagePlaying img {
  width: 70px;
  opacity: 0.5;
}

.playButton {
  width: 60%;
  margin-top: 10px;
}

.playButton:hover {
  cursor: url(/pointer.png), auto;
}

.peek {
  position: absolute;
  width: 86px;
  height: 150px;
  left: 19%;
  top: 33%;
  text-align: center;
}

.peekContainer {
  overflow: hidden;
  background-color: #DBC6BB;
  height: 131px;
  position: relative;
}

.doorLeft,
.doorRight {
  position: absolute;
  z-index: 1;
  background-color: #C8957A;
  top: 0;
  bottom: 0;
  width: 50%;
  transition: all 0.5s ease;
}

.doorLeft {
  left: 0%;
  border-right: 2px solid #000;
}

.doorRight {
  right: 0%;
  border-left: 2px solid #000;
}

.solution {
  width: 70px;
  position: absolute;
  left: 0;
  top: 0px;
}

.solutionButton {
  z-index: 6;
  border: 0;
  background-color: transparent;
  width: 43px;
  height: 43px;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0;
  margin-top: 7px;
  margin-left: 0px;
}

.solutionButton:hover {
  opacity: 0.5;
  background-color: transparent;
  cursor: url(/pointer.png), auto;
  background-size: contain;
  background-repeat: no-repeat;
}

.solutionButton:active {
  opacity: 1;
  background-color: transparent;
  cursor: url(/pointer.png), auto;
  background-size: contain;
  background-repeat: no-repeat;
}

.solutionButton:focus {
  opacity: 1;
  background-color: transparent;
  cursor: url(/pointer.png), auto;
  background-size: contain;
  background-repeat: no-repeat;
}

.playButton2 {
  width: 50px;
  opacity: 0;
  position: absolute;
  top: 65.6%;
  right: 26.4%;
  padding: 0;
  z-index: 6;
  border: 0;
  background-color: transparent;
}

.playButton2:hover,
.playButton2:active
.playButton2:focus,
.playButton2:visited {
  border: 0;
  background: transparent;
  outline: 0;
  cursor: url(/pointer.png), auto;
}

.playButton2:hover {
  opacity: 0.2;
}

.playButton2:active {
  opacity: 1;
}