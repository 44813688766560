.item {
  width: 20%;
  position: absolute;
  top: 10%;
  left: 10%;
  border: 2px solid transparent;
  border-radius: 20px;
}

.item:hover {
  /*border: 2px dashed #8300FF;*/
  border: 2px dashed #ff0000;
  border-radius: 20px;
  cursor: url('/glass.png'), auto;
}