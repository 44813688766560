.puzzleContainer {
  display: inline-block;
  border: 1px solid pink;
}

.puzzle {
  position: relative;
  cursor: url(/pointer.png), auto;
  z-index: 10;
}

.puzzleBackground {
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: -50px;
  margin-right: -50px;
  height: 650px;
  text-align: center;
  padding-top: 80px;
}