.wire {
  position: absolute;
  top: 21.4%;
  left: 24%;
  width: 27%;
}

.hole {
  height: 38px;
  width: 38px;
  border: 2px solid #000;
  background-color: rgb(60, 60, 60);
  border-radius: 38px;
  position: absolute;
}

.holeLeft1 {
  left: 24.8%;
  top: 22.8%;
}

.holeLeft2 {
  left: 24.8%;
  top: 31.2%;
}

.holeLeft3 {
  left: 24.8%;
  top: 39.5%;
}

.holeLeft4 {
  left: 24.8%;
  top: 47.7%;
}

.holeRight1 {
  left: 45.8%;
  top: 22.8%;
}

.holeRight2 {
  left: 45.8%;
  top: 31.2%;
}

.holeRight3 {
  left: 45.8%;
  top: 39.5%;
}

.holeRight4 {
  left: 45.8%;
  top: 47.7%;
}

.change,
.cut {
  width: 38px;
  height: 38px;
  position: absolute;
  opacity: 0.2;
  cursor: url(/pointer.png), auto;
}

.change:hover,
.cut:hover {
  opacity: 1;
}

.change:active,
.cut:active {
  opacity: 1;
}

.change1 {
  left: 18.8%;
  top: 22.8%;
}

.change2 {
  left: 18.8%;
  top: 31.2%;
}

.change3 {
  left: 18.8%;
  top: 39.5%;
}

.change4 {
  left: 18.8%;
  top: 47.7%;
}

.cut {
  opacity: 0.7;
}

.cut1 {
  left: 51.8%;
  top: 22.8%;
}

.cut2 {
  left: 51.8%;
  top: 31.2%;
}

.cut3 {
  left: 51.8%;
  top: 39.5%;
}

.cut4 {
  left: 51.8%;
  top: 47.7%;
}

.confirmModal {
  width: 600px !important;
}
