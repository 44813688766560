.location {
  width: 100%;
}

.container {
  position: relative;
}

.lockModal {
  width: 80%;
}

.sliceContainer {
  width: 600px;
  margin: 20px 0;
  position: relative;
  height: 170px;
}

.lock {
  width: 20%;
  margin-bottom: 20px;
}

.stepsContainer {
  overflow: hidden;
  width: 2400px;
  position: relative;
  left: 0px;
  transition: transform ease 600ms;
  transform: translate3d(0, 0, 0);
}

.stepsActive1 {
  transform: translate3d(0px, 0, 0);
}

.stepsActive2 {
  transform: translate3d(-760px, 0, 0);
}

.stepsActive3 {
  transform: translate3d(-1520px, 0, 0);
}

.step1,
.step2,
.step3 {
  width: 760px;
  padding: 0 40px;
  float: left;
  /*display: none;*/
}

.step2 svg {
  margin-top: 15px;
  margin-bottom: 10px;
}

.step2 path {
  fill: #999;
}