.container {
  text-align: center;
  position: absolute;
  right: 40px;
  top: 88px;
  font-weight: bold;
  font-size: 16px;
  z-index: 10;
}

.control {
  color: #fff;
  opacity: 0.9;
  width: 62px;
  cursor: url('/pointer.png'), auto;
  transition: opacity 300ms;
  margin-bottom: 15px;
}

.control:hover {
  opacity: 1;
}

.x {
  font-size: 3em;
  display: block;
  margin-bottom: -10px;
}

.icon {
  width: 38px;
  margin-bottom: 10px;
}

.hintIcon {
  width: 50px;
}