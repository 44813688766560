.lights {
  position: absolute;
  width: 60%;
  top: 87px;
  height: 100px;
  left: 213px;
}

.light {
  width: 71px;
  height: 77px;
  margin-right: 0px;
  background-size: 100%;
  display: none;
  background-repeat: no-repeat;
  position: relative;
}

.on {
  display: inline-block;
}

.on::after {
  content: '';
  position: absolute;
  top: 9px;
  left: 26px;
  box-shadow: rgba(198, 230, 255, 1) 0 0 46px, rgba(198, 230, 255, 1) 0 0 46px;
  height: 18px;
  width: 21px;
  border-radius: 10px;
  background-color: rgba(198, 230, 255, 0.1);
}

.speakerCutout {
  position: absolute;
  top: 28%;
  right: 27%;
}

.button {
  width: 102px;
  height: 124px;
  border: 0;
  background-color: transparent;
  position: absolute;
  background-repeat: no-repeat;
  cursor: url(/pointer.png), auto;
  opacity: 0;
}

.button:active {
  opacity: 1;
}

.buttonPlay {
  width: 181px;
  height: 157px;
  top: 236px;
  left: 495px;
}

.buttonLeft {
  top: 180px;
  left: 222px;
}

.buttonRight {
  top: 174px;
  left: 343px;
}

.buttonDolphin {
  top: 189px;
}

.buttonSeagull {
  top: 187px;
  left: 349px;
}