.modalBody {
  min-height: 740px;
  width: 850px;
  position: relative;
  margin: 0 auto;
}

.canvas {
  position: absolute;
  left: 0;
  top: -30px;
  bottom: 0;
  right: 80px;
}

.canvas:hover {
  cursor: url('/pen.png'), auto;
}

.description {
  text-align: center;
  padding: 5px 100px;
}

.clear {
  float: left;
}

.close {
  float: right;
}

.image {
  display: block;
  max-height: 620px;
  max-width: 100%;
  margin: 0 auto;
}
